<template>
  <section class="deposit-block">
    <p class="title">訂金付款設定</p>
    <BaseElForm label-position="top">
      <BaseElFormItem label="訂金金額">
        <span>$ {{ orderDeposit.price }}</span>
        <span v-if="enableClassTicketCredit && isUseClassTicket"> ( 堂票已折抵訂金 )</span>
      </BaseElFormItem>
      <BaseElFormItem label="付款方式">
        <BaseElRadioGroup v-model="syncModel" data-testid="formData_payment">
          <div class="flex flex-col" style="gap: 12px; margin-top: 12px">
            <BaseElRadio
              v-for="payment in paymentOptions"
              :key="payment.value"
              :label="payment.value"
              :disabled="disabledWallet(payment.value)"
            >
              <span v-if="payment.value === 'wallet'">{{ payment.name }}（本次使用：${{ orderDeposit.price }} / 使用後餘額：${{ walletBalance - orderDeposit.price }}）</span>
              <span v-else-if="payment.value === 'externalWallet'">{{ externalWalletName }}（本次使用：${{ orderDeposit.price }} / 使用後餘額：${{ externalWalletBalance - orderDeposit.price }}）</span>
              <span v-else>{{ payment.name }}</span>
            </BaseElRadio>
          </div>
        </BaseElRadioGroup>
      </BaseElFormItem>
    </BaseElForm>
  </section>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import { depositPaymentTypes, staticDepositPaymentTypes } from '@/config/deposit'
import { FindMember } from '@/api/member'
import { useExternalWallet } from '@/use/useExternalWallet'
import { map, get } from 'lodash'
import { mapGetters } from 'vuex'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'ReservationCreateDeposit',
  props: {
    orderDeposit: {
      type: Object,
      default: () => ({}),
    },
    model: {
      type: String,
      default: '',
    },
    depositConfig: {
      type: Object,
      default: () => ({}),
    },
    depositPaymentConfig: {
      type: Array,
      default: () => [],
    },
    memberData: {
      type: Object,
      defauilt: () => ({}),
    },
    isUseClassTicket: Boolean,
  },
  setup (props, { emit }) {
    const { getExternalWalletBalance, externalWalletBalance, externalWalletName, getExternalWalletConfig } = useExternalWallet()
    const enableClassTicketCredit = computed(() => get(props.depositConfig, 'enableClassTicketCredit'))
    const memberId = computed(() => {
      return get(props.memberData, 'member.id')
    })
    onMounted(() => {
      getExternalWalletBalance(memberId.value)
      getExternalWalletConfig()
    })
    return {
      enableClassTicketCredit,
      externalWalletBalance,
      externalWalletName,
    }
  },
  data: () => ({
    payment: '',
    member: {},
  }),
  computed: {
    ...mapGetters(['shop']),
    memberId () {
      return get(this.memberData, 'member.id')
    },
    walletBalance () {
      return get(this.member, 'Wallet.balance') || 0
    },
    paymentOptions () {
      // const options = this.depositPaymentConfig.map(i => depositPaymentTypes[i.type])
      const options = map(depositPaymentTypes, i => ({
        name: i18n.t(i.name),
        value: i.value,
        type: i.type,
      }))
      if (!this.orderDeposit.price) return [{ name: '免付訂金', value: 'free' }]
      if (!this.memberData.isMember) return options.filter(i => i.type === 'offline')
      return options.filter(i => i.type === 'offline' || i.type === 'wallet')
    },

    syncModel: {
      get () { return this.model },
      set (val) { return this.$emit('update:model', val) },
    },
  },
  watch: {
    paymentOptions () {
      if (!this.orderDeposit.price) this.syncModel = 'free'
      else this.syncModel = this.paymentOptions[0].value
    },
    async memberId (id) {
      await this.findMember(id)
      this.defaultPayment()
    },
  },
  async mounted () {
    await this.findMember(this.memberId)
    this.defaultPayment()
  },
  methods: {
   disabledWallet(type) {
      if (type === 'wallet') {
        const left = this.walletBalance - this.orderDeposit.price;
        return this.walletBalance === 0 || left < 0;
      }
      if (type === 'externalWallet') {
        const externalWalletLeft = this.externalWalletBalance - this.orderDeposit.price;
        return this.externalWalletBalance === 0 || externalWalletLeft < 0;
      }
      return false;
    },
    async findMember (id) {
      if (!id) return
      const res = await FindMember({
        shopId: this.shop,
        id,
      })
      this.member = res
    },
    defaultPayment () {
      if (!this.orderDeposit.price) this.syncModel = 'free'
      else {
        if (this.paymentOptions.length) {
          if (this.disabledWallet('wallet')) {
            const options = this.paymentOptions.filter(i => i.value !== 'wallet')
            if (options.length) this.syncModel = options[0].value
          } else {
            this.syncModel = this.paymentOptions[0].value
          }
        }
      }
    },
  },
})
</script>

<style lang="postcss" scoped>
.title {
  @apply text-primary-100 font-bold text-[18px] pb-[24px];
}

.deposit-block {
  @apply bg-[#F5F7FA] p-[20px] rounded-[8px];
}
</style>
